import config from '../../../../../config'

/* tslint:disable */
/* eslint-disable */
enum PaymentMethod {
  ITUNES = 'itunes',
  PAYPAL = 'paypal'
}

export const EXPERTPLAN = {
  storage: 'Unlimited Storage',
  photos: 'Unlimited Photos'
}

const LINK = {
  payPal: 'Paypal Support',
  iTunes: 'iTunes Support'
}

const TITLE = {
  payPal: 'PayPal Subscription',
  iTunes: 'iTunes Subscription'
}

const CANELCONTENT = {
  payPal: 'If you would like to cancel to your PayPal membership, please follow the PhotoBucket support link:',
  iTunes: 'If you would like to cancel to your iTunes membership, please follow the link:'
}

const CHANGECONTENT = {
  payPal: 'If you would like to make a change to your PayPal membership, please follow the PhotoBucket support link:',
  iTunes: 'If you would like to make a change to your iTunes membership, please follow the link:'
}

export const NOPLAN = 'Not Available'

export const CANCELPBTITLE = {
  default: 'Sure you want to cancel?',
  backupPlan: 'Wait a sec! Why not put it on Ice?'
}
export const CANCELPBCONTENT = {
  default:
    "We hope not! Members like you are what makes Photobucket amazing. To find the storage option that's right for you, upgrade or change your plan below.",
  backupPlan:
    'Introducing our Ice Bucket Plan for your long-term storage needs. An exclusive new plan for our existing customers! It offers a basic and affordable way to keep your memories safe, without paying for extra features.'
}
export const CANCELPBLINK = 'Continue Cancellation'
export const UPGRADEPBLINK = 'Upgrade or Change Membership'
export const CANCELPBURL = config.appUrl.cancelSubscription

export function getTitle(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? TITLE.iTunes : TITLE.payPal
}

export function getLink(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? LINK.iTunes : LINK.payPal
}

export function getCancelUrl(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? config.appUrl.iTuneCancel : config.appUrl.met
}

export function getChangeUrl(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? config.appUrl.iTunesChange : config.appUrl.met
}

export function getCancelContent(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? CANELCONTENT.iTunes : CANELCONTENT.payPal
}

export function getChangeContent(paymentType: string) {
  return paymentType === PaymentMethod.ITUNES ? CHANGECONTENT.iTunes : CHANGECONTENT.payPal
}

export const cancellationUrls = {
  'backup-annual': 'https://my.photobucket.com/cancel-step-1-mb-annual',
  'backup-monthly': 'https://my.photobucket.com/cancel-step-1-mb-monthly',
  'beginner-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'beginner-biennial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'beginner-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'expert-annual': 'https://my.photobucket.com/cancel-step-1-hosting-monthly',
  'expert-biennial': 'https://my.photobucket.com/cancel-step-1-hosting-monthly',
  'expert-monthly': 'https://my.photobucket.com/cancel-step-1-hosting-monthly',
  'expert-trial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'forever-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'forever-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'intermediate-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'intermediate-biennial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'intermediate-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'intermediate-trial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'no-plan': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus100-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plus100-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus20-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plus20-biennial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus20-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus20-quarterly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus200-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plus200-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus50-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plus50-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus500-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plus500-biennial': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plus500-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'plusadfree-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'plusadfree-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'sharing-annual': 'https://my.photobucket.com/cancel-step-1-gb-annual',
  'sharing-monthly': 'https://my.photobucket.com/cancel-step-1-gb-monthly',
  'sharing-trial': 'https://my.photobucket.com/cancel-step-1-free-trial',
  'storage-annual': 'https://my.photobucket.com/cancel-step-1-mb-annual',
  'storage-monthly': 'https://my.photobucket.com/cancel-step-1-mb-monthly',
  trial: 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'vault-annual': 'https://my.photobucket.com/cancel-step-1-legacy-annual',
  'vault-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly',
  'vip-monthly': 'https://my.photobucket.com/cancel-step-1-legacy-monthly'
}

export function getPlanDisplayName(planName: string) {
  const planNameMap = {
    'backup-annual': 'Personal Storage Annual',
    'backup-monthly': 'Personal Storage Monthly',
    'beginner-annual': 'Beginner Annual',
    'beginner-biennial': 'Beginner Biennial',
    'beginner-monthly': 'Beginner Monthly',
    'expert-annual': 'Hosting Annual',
    'expert-biennial': 'Hosting Biennial',
    'expert-monthly': 'Hosting Monthly',
    'expert-trial': 'Hosting Trial',
    'forever-annual': 'Forever Annual',
    'forever-monthly': 'Forever Monthly',
    'intermediate-annual': 'Intermediate Annual',
    'intermediate-biennial': 'Intermediate Biennial',
    'intermediate-monthly': 'Intermediate Monthly',
    'intermediate-trial': 'Intermediate Trial',
    'no-plan': 'No Plan',
    'plus100-annual': 'Plus100 Annual',
    'plus100-monthly': 'Plus100 Monthly',
    'plus20-annual': 'Plus20 Annual',
    'plus20-biennial': 'Plus20 Biennial',
    'plus20-monthly': 'Plus20 Monthly',
    'plus20-quarterly': 'Plus20 Quarterly',
    'plus200-annual': 'Plus200 Annual',
    'plus200-monthly': 'Plus200 Monthly',
    'plus50-annual': 'Plus50 Annual',
    'plus50-monthly': 'Plus50 Monthly',
    'plus500-annual': 'Plus500 Annual',
    'plus500-biennial': 'Plus500 Biennial',
    'plus500-monthly': 'Plus500 Monthly',
    'plusadfree-annual': 'Plusadfree Annual',
    'plusadfree-monthly': 'Plusadfree Monthly',
    'sharing-annual': 'Group Sharing Annual',
    'sharing-monthly': 'Group Sharing Monthly',
    'sharing-trial': 'Group Sharing Free Trial',
    'storage-annual': 'Personal Storage Annual',
    'storage-monthly': 'Personal Storage Monthly',
    trial: 'Trial',
    'vault-annual': 'Vault Annual',
    'vault-monthly': 'Vault Monthly',
    'vip-monthly': 'VIP Monthly'
  }

  planName = planName.toLowerCase()
  return planNameMap[planName as keyof typeof planNameMap]
}
