import { DeleteOutline } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { modalVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucket from '../../../hooks/useBucket'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'
import useBucketConvertProgress from '../../../hooks/useBucketConvertProgress'

export default function MediaDelete() {
  const { selectedMedia } = useSelectedMedia()
  const { bucketUser } = useBucket()
  const mediaDeletePermission = bucketUser?.permissions?.mediaDelete
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  const isBucketConvertInProgressForBucket = useBucketConvertProgress()

  let title = 'Move To Trash'
  if (isMigrationInProgressForBucket || isBucketConvertInProgressForBucket) {
    title = 'Media delete is disabled during the migration process'
  } else if (!mediaDeletePermission) {
    title = 'Your role for this bucket does not allow you to delete media.'
  } else if (!selectedMedia.size) {
    title = 'No Media Selected'
  }

  const disabled = !selectedMedia.size || !mediaDeletePermission || isMigrationInProgressForBucket || isBucketConvertInProgressForBucket

  const handleClick = () => {
    modalVar('bucketMediaDelete')
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          disabled={disabled}
          color="inherit"
          aria-label="Move To Trash"
          onClick={handleClick}
          id="move-to-trash-button"
          data-testid="buckets-appbar-move-to-trash-button"
        >
          <DeleteOutline />
        </IconButton>
      </div>
    </Tooltip>
  )
}
